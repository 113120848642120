import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const Header = styled.h2`
    font-size: 4.8rem;
    margin-bottom: 6.8rem;
    text-align: center;

    @media screen and (max-width: ${sizes.tablet}) {
        font-size: 4rem;
        margin-bottom: 6rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
        margin-bottom: 4rem;
    };
`;

const GalleryContainer = styled.section`
    margin: auto;
    padding: 12em;
    max-width: 1440px;

    @media screen and (max-width: ${sizes.tablet}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const GalleryImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2.4em;
    row-gap: 6.8em;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        row-gap: 4em;
    };
`;

const ImageContainer = styled.div`
    width: 22%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    img {
        border-radius: 25px;
    }

    @media screen and (max-width: ${sizes.tablet}) {
        width: 44%;
    };
`;

const TitleContainer = styled.div`
    text-align: center;
    padding: 34px 0px 0px 0px;
`;

const Title = styled.p`
    line-height: 1em;
    font-size: 2rem;
    font-weight: 700;
    margin: 0px 0px 8px 0px;
`;

const SubTitle = styled.p`
    font-size: 1.4rem;
    font-weight: 400;
`;

const PhotoGallery = ({ headerString, galleryImageStrings}) => {
    const galleryImages = galleryImageStrings.map((galleryImage) => {
        const key = galleryImage.TITLE.toLocaleLowerCase().split(' ').join('-');
        return (
            <ImageContainer key={key}>
                <img src={galleryImage.IMAGE} alt='headshot'/>
                <TitleContainer>
                    <Title>
                        {galleryImage.TITLE}
                    </Title>
                    <SubTitle>
                        {galleryImage.SUBTITLE}
                    </SubTitle>
                </TitleContainer>
            </ImageContainer>
        );
    });
    return (
        <GalleryContainer>
            <Header>
                {headerString}
            </Header>
            <GalleryImageContainer>
                {galleryImages}
            </GalleryImageContainer>
        </GalleryContainer>
    );
};

export default PhotoGallery;