import React from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import ImageText from './shared/ImageText';
import ModularBanner from './shared/ModularBanner';
import GradientBanner from './shared/GradientBanner';
import CTABanner from './shared/CTABanner';
import AccordionSection from './shared/AccordionSection';

const ServiceProviders = () => {
    return (
        <div>
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.SERVICEPROVIDERS_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.SERVICEPROVIDERS_HERO.SUB_HEADER}
                hasButton
                path={'https://oncourseprovidernetwork.com/'}
                src={'/images/service-providers-hero.png'}
                altText={'a yellow hard hat, a tool belt with tools in it, work gloves, and a level'}
                buttonString={'LEARN MORE'}
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.SERVICEPROVIDERS_SIMPLE_BANNER_STRING} />
            <ImageText
                src={'/images/service-providers-textimage.jpg'}
                altText={'person with clipboard speaking to a homeowner'}
                mainHeader={APPLICATION_CONSTANTS.SERVICEPROVIDERS_IMAGETEXT.MAIN_HEADER}
                subHeader1={APPLICATION_CONSTANTS.SERVICEPROVIDERS_IMAGETEXT.SUB_HEADER1}
                subHeader2={APPLICATION_CONSTANTS.SERVICEPROVIDERS_IMAGETEXT.SUB_HEADER2}
            />
            <ModularBanner
                contentStrings={APPLICATION_CONSTANTS.SERVICEPROVIDERS_MODULARBANNER_STRINGS}
                styles={{
                    backgroundColor: 'var(--oncourse-blue)'
                }}
            />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.SERVICEPROVIDERS_GRADIENTBANNER_MAIN_HEADER}
                cardStrings={APPLICATION_CONSTANTS.SERVICEPROVIDERS_GRADIENTBANNER_CARD_STRINGS}
            />
            <SimpleBanner type={"quote"} string={APPLICATION_CONSTANTS.SERVICEPROVIDERS_SIMPLE_QUOTE_BANNER_STRING} />
            <CTABanner 
                buttonString={"APPLY NOW!"}
                path={'https://oncourseprovidernetwork.com/'}
                size={"large"}
                strings={APPLICATION_CONSTANTS.SERVICEPROVIDERS_CTABANNER1_STRINGS}
            />
            <AccordionSection 
                headerString={APPLICATION_CONSTANTS.SERVICEPROVIDERS_ACCORDION_SECTION_HEADER} 
                accordionStrings={APPLICATION_CONSTANTS.SERVICEPROVIDERS_ACCORDION_STRINGS} 
            />
            <CTABanner strings={APPLICATION_CONSTANTS.SERVICEPROVIDERS_CTABANNER2_STRINGS} />
        </div>
    );
};

export default ServiceProviders;